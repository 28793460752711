export const PRINT_OPTION = [
	{ label: 'Print Foil Label', value: '0' },
	{ label: 'Print Pack Number', value: '1' },
	{ label: 'Print DOB', value: '2' }
];
export const PRINT_OPTION_ENUM = {
	PRINT_FOIL_LABEL: '0',
	PRINT_PACK_NUMBER: '1',
	PRINT_DOB: '2'
};

export const PRINT_ONLY = {
	STANDARD: 'STANDARD',
	SHORTCOURSE: 'SHORTCOURSE',
	WARFARIN: 'WARFARIN',
	SINEMET: 'SINEMET',
	S8: 'S8',
	PRN: 'PRN',
	CYTOTOXIC: 'CYTOTOXIC',
	S4N: 'S4N'
};
export const PRESCRIPTION_FREQUENCY = [
  {
    label: 'Daily',
    value: '0',
  },
  {
    label: 'Alt Day',
    value: '1',
  },
  {
    label: 'On-Off Day',
    value: '2',
  },
  {
    label: 'Every 3rd day',
    value: '3',
  },
  {
    label: 'Monthly',
    value: '4',
  },
  {
    label: 'Every X Days',
    value: '5',
  },
  {
    label: 'Specific Days',
    value: '6',
  },
  {
    label: 'Fortnightly',
    value: '14',
  },
  {
    label: 'Every 28 Days',
    value: '28',
  },
  {
    label: 'Half Yearly',
    value: '7',
  },
  {
    label: 'Yearly',
    value: '8',
  },
  {
    label: 'Every X Weeks',
    value: '9',
  },
];

export const DOSE_TIME = {
	BREAKFAST: '0',
	LUNCH: '1',
	DINNER: '2',
	BEDTIME: '3'
};
