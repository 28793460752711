import { StorageService } from 'app/services';
import { PRINT_OPTION_STORAGE_KEY } from '../../constants';
import * as ScriptRequestActions from '../actions';

const data = JSON.parse(StorageService.getValue(PRINT_OPTION_STORAGE_KEY));

const initialState = {
  filter: {
    patientGroupIds: [],
    weekNumber: '1',
    patientIds: [],
    doctorIds: [],
  },
  printOptions: {
    includePRN: data?.includePRN || false,
    includeNonPacked: data?.includeNonPacked || false,
    includeCeased: data?.includeCeased || false,
    includeSuspended: data?.includeSuspended || false,
    isPrintPerPatient: data?.isPrintPerPatient || false,
    isIncludeMedicationDetails: data?.isIncludeMedicationDetails || false,
    includeScriptWithRepeatsLeft: data?.includeScriptWithRepeatsLeft || false,
    repeatsLeft: data?.repeatsLeft || 0,
    includedIds: [],
    excludedIds: [],
  },
  printing: false,
  sending: false,
  fileName: null,
  fileUrl: null,
};

const listReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // SCRIPT_REQUEST_PRINT_REQUEST
    case ScriptRequestActions.SCRIPT_REQUEST_PRINT_REQUEST:
      return { ...state, printing: true };
    case ScriptRequestActions.SCRIPT_REQUEST_PRINT_REQUEST_SUCCESS:
      return { ...state, printing: false, ...payload };
    case ScriptRequestActions.SCRIPT_REQUEST_PRINT_REQUEST_FAIL:
      return { ...state, printing: false };
    // SCRIPT_REQUEST_CHANGE_FILTER
    case ScriptRequestActions.SCRIPT_REQUEST_CHANGE_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...payload },
        printOptions: { ...state.printOptions },
      };
    // SCRIPT_REQUEST_APPLY_PRINT_OPTIONS
    case ScriptRequestActions.SCRIPT_REQUEST_APPLY_PRINT_OPTIONS:
      return { ...state, printOptions: { ...state.printOptions, ...payload } };
    // SCRIPT_REQUEST_CLEAR_HISTORY_FILE
    case ScriptRequestActions.SCRIPT_REQUEST_CLEAR_HISTORY_FILE:
      return { ...state, fileName: null, fileUrl: null };
    // SCRIPT_REQUEST_RESET
    case ScriptRequestActions.SCRIPT_REQUEST_RESET:
      return { ...initialState, printOptions: { ...state.printOptions } };
    default:
      return state;
  }
};

export default listReducer;
