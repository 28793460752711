import * as JobActions from '../actions';

const initState = {
  submitting: false,
  submitSuccess: false,
  deleteSuccess: false,
  error: null,
  message: '',
  data: {
    fileName: '',
    fileUrl: '',
  },
  blisterType: {
    clientCode: '',
    packType: '',
  },
  summary: {
    printingCount: 0,
    packingCount: 0,
    correctionCount: 0,
    checkingCount: 0,
    distributionCount: 0,
    failedCount: 0,
    completeCount: 0,
  },
  generating: false,
  printAndPackSuccess: false,
  jobList: null,
  barcode: '',
  isQuickkingJob: false,
  confirmPassWordSuccess: false,
};

const listReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case JobActions.PRINT_JOB:
      return {
        ...state,
        submitting: true,
        blisterType: { clientCode: payload.clientCode, packType: payload.packType },
      };
    case JobActions.PRINT_JOB_SUCCESS:
      return { ...state, submitting: false, submitSuccess: true, data: { ...state.data, ...payload } };
    case JobActions.PRINT_JOB_FAIL:
      return { ...state, submitting: false, submitSuccess: false, error: payload.error, message: payload.message };
    case JobActions.DELETE_JOB:
      return { ...state, submitting: true, deleteSuccess: false };
    case JobActions.DELETE_JOB_SUCCESS:
      return { ...state, submitting: false, deleteSuccess: true };
    case JobActions.DELETE_JOB_FAIL:
      return { ...state, submitting: false, deleteSuccess: false, error: payload.error, message: payload.message };
    case JobActions.REVERT_BACK_TO_DISTRIBUTION:
      return { ...state, submitting: true };
    case JobActions.REVERT_BACK_TO_DISTRIBUTION_SUCCESS:
      return { ...state, submitting: false };
    case JobActions.REVERT_BACK_TO_DISTRIBUTION_FAIL:
      return { ...state, submitting: false, error: payload.error, message: payload.message };
    case JobActions.RESET_DATA:
      return { ...initState };
    case JobActions.RESET_FILE_DATA:
      return { ...state, submitSuccess: false, data: { fileName: '', fileUrl: '' }, printAndPackSuccess: false };
    case JobActions.GENERATE_DATA:
      return { ...state, generating: true };
    case JobActions.GENERATE_DATA_SUCCESS:
      return { ...state, generating: false };
    case JobActions.GENERATE_DATA_FAIL:
      return { ...state, generating: false, error: payload.error, message: payload.message };
    case JobActions.PRINT_AND_PACK:
      return {
        ...state,
        submitting: true,
        blisterType: { clientCode: payload.clientCode, packType: payload.packType },
        printAndPackSuccess: false,
      };
    case JobActions.PRINT_AND_PACK_SUCCESS:
      return { ...state, submitting: false, printAndPackSuccess: true, data: { ...state?.data, ...payload } };
    case JobActions.PRINT_AND_PACK_FAIL:
      return {
        ...state,
        submitting: false,
        printAndPackSuccess: false,
        error: payload.error,
        message: payload.message,
      };
    // Print Label
    case JobActions.PRINT_LABEL:
      return { ...state, submitting: true, submitSuccess: false, error: null, message: '' };
    case JobActions.PRINT_LABEL_SUCCESS:
      return { ...state, submitting: false, submitSuccess: true, data: { ...state?.data, ...payload } };
    case JobActions.PRINT_LABEL_FAIL:
      return {
        ...state,
        submitting: false,
        error: payload.error,
        message: payload.message,
      };
    case JobActions.GET_JOB_SUMMARY_DONE:
      return { ...state, summary: { ...state.summary, ...action.payload } };
    case JobActions.CHECK_IS_BARCODE:
      return { ...state, barcode: action.payload };
    case JobActions.GET_BARCODE_LIST:
      return { ...state, jobList: action.payload };
    case JobActions.QUICKKING_JOB_SUCCESS:
      return { ...state, isQuickkingJob: true };
    case JobActions.CLEAR_BARCODE:
      return { ...state, jobList: null, barcode: '', isQuickkingJob: false };

    // CHECK_CONFIRM_PASSWORD_REQUIRED
    case JobActions.CHECK_CONFIRM_PASSWORD_REQUIRED:
      return { ...state, loading: true, error: false, message: '' };
    case JobActions.CHECK_CONFIRM_PASSWORD_REQUIRED_SUCCESS:
      return { ...state, loading: false, confirmPassWordSuccess: true, isQuickkingJob: true };

    case JobActions.CHECK_CONFIRM_PASSWORD_REQUIRED_SUCCESS_END:
      return { ...state, loading: false, confirmPassWordSuccess: false };

    case JobActions.CHECK_CONFIRM_PASSWORD_REQUIRED_FAIL:
      return { ...state, loading: false, error: true, message: payload.message };

    default:
      return state;
  }
};

export default listReducer;
